import { Backdrop, Checkbox, Divider, FormControlLabel, IconButton, Link } from "@mui/material";
import { FC, useContext, useState, useEffect, Fragment, useCallback } from "react";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CloseIcon from "@mui/icons-material/Close";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { isDesktop, isIOS, isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import OTPInput, { ResendOTP } from "otp-input-react";
import { Paths } from "../../Common/AppRoutes";
import { AuthContext } from "../../Contexts/AuthContext";
import { AccountType } from "../../Enums/AccountType";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";
import { EMAIL_REGEX, RECAPTCHA_KEY } from "../../Common/Constants";
import Error from "../../Common/Error";
import { Desktop, Mobile } from "../../Common/Responsive";
import { capitalizeFirstChar, maskEmail, pushTagEvent } from "../../Common/Helper";
import Popup from "../../Common/Popup";
import { useComponentDidMount } from "../../Common/FCUtils";

interface LoginProps {
    handleClose: (didSignup: boolean) => void;
    promoCode?: string;
    defaultEmail?: string;
    showSignupByDefault?: boolean;
    title?: string;
    subTitle?: string;
    dividerText?: string;
    disableSignup?: boolean;
    disableCloseBtn?: boolean;
}

enum States {
    LOGIN_INITIAL_SCREEN = "LOGIN_INITIAL_SCREEN",
    LOGIN_USER_DETAIL = "LOGIN_USER_DETAIL",
    SIGNUP_INITIAL_SCREEN = "SIGNUP_INITIAL_SCREEN",
    SIGNUP_USER_DETAIL = "SIGNUP_USER_DETAIL",
    SIGNUP_VERIFY_USER = "SIGNUP_VERIFY_USER"
}

export const inputStyles = {
    cursor: "pointer",
    border: 0,
    width: "50px",
    height: "50px",
    fontSize: "24px",
    borderRadius: 0,
    borderBottom: "3px solid #cbcbcb",
    padding: 0
};

const LoginComponent: FC<LoginProps> = (props: LoginProps) => {
    let retryCount = 0;
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
    const [userCaptchaVerified, setUserCaptchaVerified] = useState<boolean>(true);
    const [showInvalidPswdError, setShowInvalidPswdError] = useState<boolean>(false);
    const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(true);
    const [verifyPasswordHidden, setVerifyPasswordHidden] = useState<boolean>(true);
    const [accountTypeOfRegisteredUser, setAccountTypeOfRegisteredUser] = useState<string>("");
    const [isLogin, setLogin] = useState<boolean>(!props.showSignupByDefault && !props.promoCode);
    const [state, setState] = useState<States>(isLogin ? States.LOGIN_INITIAL_SCREEN : States.SIGNUP_INITIAL_SCREEN);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [showEmbeddedBrowserError, setShowEmbeddedBrowserError] = useState<boolean>(false);
    const [isVerifyingCode, setIsVerifyingCode] = useState<boolean>(false);
    const [codeError, setCodeError] = useState("");
    const { register, formState, handleSubmit, watch, setValue, setError, clearErrors, getValues } = useForm({
        defaultValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            verifyPassword: ""
        },
        mode: "onChange"
    });
    const { executeRecaptcha } = useGoogleReCaptcha();

    const isEmbeddedBrowser = () => {
        // @ts-ignore
        var standalone = window.navigator.standalone,
            userAgent = window.navigator.userAgent.toLowerCase(),
            safari = /safari/.test(userAgent);
        if (!standalone && !safari && isIOS) {
            return true;
        }
        return false;
    };

    useComponentDidMount(() => {
        if (isEmbeddedBrowser()) {
            setShowEmbeddedBrowserError(true);
            return;
        }
    });

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        try {
            const token = await executeRecaptcha("SignInDialogOpen");
            HttpService.postFormData(`/v1/siteverify/token`, { token }, true)
                .then(data => setUserCaptchaVerified(data.is_valid))
                .catch(error => setUserCaptchaVerified(true));
        } catch (error) {
            retryCount++;
            if (retryCount > 5) {
                setUserCaptchaVerified(true); // enable if all fails
                return;
            }
            console.log("executeRecaptcha failed, retrying ", retryCount);
            setTimeout(() => handleReCaptchaVerify(), 500);
        }
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    useEffect(() => {
        if (!stayLoggedIn) ToastService.warn("Please log out when you end your session.");
    }, [stayLoggedIn]);

    useEffect(() => {
        if (!!props.defaultEmail) {
            HttpService.postFormData("/v1/user/validate", { email: props.defaultEmail }, false, true).then(response => {
                if (response) {
                    if (!response.user_exists || (response.user_exists && !response.is_active)) {
                        setState(States.SIGNUP_INITIAL_SCREEN);
                        setLogin(false);
                    }
                    setValue("email", props.defaultEmail || "");
                }
            });
        }
    }, [props.defaultEmail, setValue]);

    useEffect(() => {
        const subscription = watch((value: any) => {
            if (value.password && value.verifyPassword && value.password !== value.verifyPassword) {
                setError("root.passwordNotMatching", {
                    type: "custom"
                });
            } else clearErrors("root.passwordNotMatching");
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    useEffect(() => {
        if (state === States.LOGIN_INITIAL_SCREEN) {
            pushTagEvent("e_login_screen");
        } else if (state === States.LOGIN_USER_DETAIL) {
            pushTagEvent("e_login_password_prompt");
        }
    }, [state]);

    const getTitle = (): string => {
        switch (state) {
            case States.LOGIN_INITIAL_SCREEN:
            case States.LOGIN_USER_DETAIL:
                return `${props.title || defaultTitle()}`.replace(/sign up/gi, "log in");
            case States.SIGNUP_INITIAL_SCREEN:
            case States.SIGNUP_USER_DETAIL:
                return `${props.title || defaultTitle()}`.replace(/log in/gi, "sign up");
        }
        return props.title || defaultTitle();
    };

    const defaultTitle = () => {
        if (props.promoCode) {
            return "Welcome to Emovid";
        }
        switch (state) {
            case States.LOGIN_INITIAL_SCREEN:
                return "Welcome back";
            case States.LOGIN_USER_DETAIL:
                return "Enter your password";
            case States.SIGNUP_INITIAL_SCREEN:
            case States.SIGNUP_USER_DETAIL:
                return "Create your account";
            case States.SIGNUP_VERIFY_USER:
                return "Please verify your e-mail";
        }
    };

    const promoCodeSubtitle = (): string => {
        if (props.promoCode?.indexOf("cap_") === 0) return "Welcome to the free trial\nNo credit card required";
        return "This special link has granted you early access to Emovid. Welcome aboard!";
    };

    const signIn = async (event: any, accountType: AccountType) => {
        event.preventDefault();
        pushTagEvent("e_login_social", { account_type: accountType });
        await authContext.signIn(accountType, props.defaultEmail || "");
    };

    const copyLinkToClipboard = async () => {
        try {
            await navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([`${window.location.href}`], { type: "text/plain" }) })]);
            ToastService.success("Copied to clipboard!");
        } catch (error: any) {
            ToastService.alertableError("Unable to copy to clipboard!", error);
        }
    };

    const embeddedBrowserDialogCloseClick = () => {
        setShowEmbeddedBrowserError(false);
        closeDialogWindow();
    };

    const handleLoginAndSignup = async (data: any) => {
        sessionStorage.setItem("redirectPath", window.location.pathname + window.location.search);
        sessionStorage.setItem("loginFlowEmail", data.email);
        setLoading(true);
        setAccountTypeOfRegisteredUser("");
        const notSignedUpMessage = `We couldn't find an account associated with ${data.email}. Please signup instead.`;
        let response;
        switch (state) {
            case States.LOGIN_INITIAL_SCREEN:
            case States.SIGNUP_INITIAL_SCREEN:
                response = await HttpService.postFormData("/v1/user/validate", { email: data.email }, false, true);
                if (response) {
                    if (response.user_exists && response.is_active) {
                        if (response.account_type === AccountType.EMAIL || response.has_password) {
                            if (state === States.SIGNUP_INITIAL_SCREEN) ToastService.info("This email is already signed up! Please login instead.");
                            setLogin(true);
                            setState(States.LOGIN_USER_DETAIL);
                        } else if (!response.account_type) {
                            if (!!props.defaultEmail) showSignupPage();
                            else ToastService.info(notSignedUpMessage);
                        } else setAccountTypeOfRegisteredUser(response.account_type);
                    } else if (!response.user_exists || (response.user_exists && !response.is_active)) {
                        if (state === States.LOGIN_INITIAL_SCREEN) ToastService.info(notSignedUpMessage);
                        if (response.name) setValue("first_name", (response.name || "").split(" ")[0] || "");
                        if (response.name) setValue("last_name", (response.name || "").split(" ")[1] || "");
                        showSignupPage();
                    }
                }
                break;
            case States.LOGIN_USER_DETAIL:
                try {
                    response = await HttpService.postFormData("/v1/user/signin", {
                        email: data.email,
                        password: data.password
                    });
                    await authContext.retrieveUserDataFromTokenAndRedirect(response.token);
                    closeDialogWindow();
                } catch (error) {
                    setLoading(false);
                    setShowInvalidPswdError(true);
                    setValue("password", "");
                    return;
                }
                break;
            case States.SIGNUP_USER_DETAIL:
                try {
                    response = await HttpService.postFormData("/v1/user/signup", {
                        email: data.email,
                        name: `${data.first_name} ${data.last_name}`.trim(),
                        password: data.password
                    });
                    setState(States.SIGNUP_VERIFY_USER);
                } catch (error: any) {
                    setLoading(false);
                    ToastService.alertableError("User already exists with entered email. Please try with a different email or login.", error);
                    return;
                }
                break;
        }
        setLoading(false);
    };

    const showSignupPage = () => {
        setLogin(false);
        setState(States.SIGNUP_USER_DETAIL);
    };

    const verifyCode = async (code: string) => {
        setCodeError("");
        setCode(code);
        if (!code) {
            setCodeError("Please enter the code");
            return;
        }
        if (code.length !== 4) {
            return;
        }
        setIsVerifyingCode(true);
        try {
            const response = await HttpService.postFormData("/v1/user/verify/email/code", { email: getValues("email"), code }, false, true);
            await authContext.retrieveUserDataFromTokenAndRedirect(response.token);
            setIsVerifyingCode(false);
            closeDialogWindow(true);
        } catch (error: any) {
            setIsVerifyingCode(false);
            setCodeError(error.message);
            return;
        }
    };

    const resendCode = async () => {
        setLoading(true);
        try {
            await HttpService.postFormData("/v1/user/resend/email/code", { email: getValues("email") }, false, true);
            ToastService.success("Code sent successfully");
        } catch (error: any) {
            setLoading(false);
            return;
        }
    };

    const forgotPasswordClicked = () => {
        pushTagEvent("e_login_forgot_password");
    };

    const setPasswordClicked = () => {
        navigate(Paths.resetPassword);
    };

    const backBtnClicked = () => {
        setLogin(!props.showSignupByDefault);
        setState(!!props.defaultEmail || props.showSignupByDefault ? States.SIGNUP_INITIAL_SCREEN : States.LOGIN_INITIAL_SCREEN);
    };

    const closeDialogWindow = (didSignup = false) => {
        props.handleClose?.(didSignup);
    };

    const isSignupDisabled = (): boolean => {
        return !!props.disableSignup && !props.promoCode;
    };

    return (
        <div className="container login-dialog p-2">
            <h3 className="text-white m-auto pb-2 pt-0 text-center fs-4" style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: getTitle() }}></h3>
            {!!props.defaultEmail && <div className="text-center">with {props.defaultEmail}</div>}
            {!props.disableCloseBtn && (
                <IconButton
                    className="close-icon text-white"
                    aria-label="Close"
                    onClick={() => closeDialogWindow()}
                    style={{ top: "20px", right: isDesktop || isTablet ? "" : "0px" }}
                >
                    <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
            )}
            {props.subTitle && <div className="text-white m-auto pb-2 text-center break-spaces">{props.subTitle}</div>}
            {props.promoCode && <div className="text-white m-auto pb-2 pt-3 text-center break-spaces">{promoCodeSubtitle()}</div>}
            {!accountTypeOfRegisteredUser && [States.LOGIN_INITIAL_SCREEN, States.SIGNUP_INITIAL_SCREEN].includes(state) && (
                <>
                    <div className="col-sm login-card mb-2 mt-3">
                        <div className="d-flex social-buttons flex-wrap pb-2">
                            <button
                                className="btn btn-lg google-button flex-grow-1 d-flex justify-content-md-around w-100 mb-4"
                                disabled={!userCaptchaVerified}
                                onClick={event => signIn(event, AccountType.GOOGLE)}
                            >
                                <img className="icon" src="/icons/google.svg" alt="google" />
                                <label className="text m-auto">Continue with Google</label>
                            </button>
                            <button
                                className="btn btn-lg google-button flex-grow-1 d-flex justify-content-md-around w-100 mb-4"
                                disabled={!userCaptchaVerified}
                                onClick={event => signIn(event, AccountType.MICROSOFT)}
                            >
                                <img className="icon" src="/icons/microsoft.svg" alt="microsoft" />
                                <label className="text m-auto">Continue with Microsoft</label>
                            </button>
                            <button
                                className="btn btn-lg google-button flex-grow-1 d-flex justify-content-md-around w-100"
                                disabled={!userCaptchaVerified}
                                onClick={event => signIn(event, AccountType.MICROSOFT_AZURE_AD)}
                            >
                                <img className="icon" src="/icons/microsoft_entra.svg" alt="microsoft entra" />
                                <label className="text m-auto">Approved Corporate SSO</label>
                            </button>
                        </div>
                        <FormControlLabel
                            control={<Checkbox checked={stayLoggedIn} onChange={e => setStayLoggedIn(e.target.checked)} name="stay-logged-in" />}
                            className="stay-logged-in-checkbox"
                            label="Keep me logged in"
                        />
                    </div>
                    <Divider>{props.dividerText || "OR"}</Divider>
                </>
            )}
            {!accountTypeOfRegisteredUser && ![States.SIGNUP_VERIFY_USER].includes(state) && (
                <>
                    <form className="position-relative" onSubmit={handleSubmit(handleLoginAndSignup)}>
                        <div className="form-group mt-2">
                            <label className="p-1">Email Address*</label>
                            <input
                                type="email"
                                className={formState.errors.email && formState.touchedFields.email ? "form-control error pb-2 pt-2" : "form-control pb-2 pt-2"}
                                {...register("email", { required: true, pattern: EMAIL_REGEX })}
                                placeholder="Enter email"
                            />
                            {formState.errors.email && formState.errors.email?.type === "pattern" && <Error>Please enter a valid email</Error>}
                        </div>
                        {!isLogin && state === States.SIGNUP_USER_DETAIL && (
                            <div className="d-flex row">
                                <div className="form-group pt-3 line-item col-sm-12 col-md-6">
                                    <label className="pb-2">First name*</label>
                                    <input
                                        type="text"
                                        className={"form-control pb-2 pt-2 pe-2 " + (formState.errors.first_name && formState.touchedFields.first_name ? "error" : "")}
                                        {...register("first_name", { required: true })}
                                        placeholder="Enter first name"
                                    />
                                    {formState.errors.first_name && formState.errors.first_name?.type === "required" && <Error>Please enter first name</Error>}
                                </div>
                                <div className="form-group pt-3 line-item col-sm-12 col-md-6">
                                    <label className="pb-2">Last name</label>
                                    <input
                                        type="text"
                                        className={"form-control pb-2 pt-2 pe-2 " + (formState.errors.last_name && formState.touchedFields.last_name ? "error" : "")}
                                        {...register("last_name")}
                                        placeholder="Enter last name"
                                    />
                                </div>
                            </div>
                        )}
                        {[States.LOGIN_USER_DETAIL, States.SIGNUP_USER_DETAIL].includes(state) && (
                            <>
                                <div className="form-group mt-2">
                                    <label className="p-1">Password*</label>
                                    <input
                                        type={passwordHidden ? "password" : "text"}
                                        className={formState.errors.password && formState.touchedFields.password ? "form-control error pb-2 pt-2" : "form-control pb-2 pt-2"}
                                        {...register("password", { required: true })}
                                        onClick={() => setShowInvalidPswdError(false)}
                                        placeholder="Enter password"
                                    />
                                    <span className="eye-icon clickable text-primary" onClick={() => setPasswordHidden(!passwordHidden)}>
                                        <i className={passwordHidden ? "fa fa-eye-slash" : "fa fa-eye"} id="togglePassword"></i>
                                    </span>
                                </div>
                                {showInvalidPswdError && (
                                    <div className="mt-2">
                                        <ErrorRoundedIcon />
                                        <label className="p-1">Invalid email or password. Please try again.</label>
                                    </div>
                                )}
                                {state === States.SIGNUP_USER_DETAIL && (
                                    <>
                                        <div className="form-group mt-2">
                                            <label className="p-1">Verify Password*</label>
                                            <input
                                                type={verifyPasswordHidden ? "password" : "text"}
                                                className={"form-control pb-2 pt-2 " + (formState.errors.verifyPassword && formState.touchedFields.verifyPassword ? "error" : "")}
                                                {...register("verifyPassword", { required: true })}
                                                placeholder="Re-enter password"
                                            />
                                            <span className="eye-icon clickable text-primary" onClick={() => setVerifyPasswordHidden(!verifyPasswordHidden)}>
                                                <i className={verifyPasswordHidden ? "fa fa-eye-slash" : "fa fa-eye"} id="toggleVerifyPassword"></i>
                                            </span>
                                        </div>
                                        {formState.errors.root && formState.errors.root.passwordNotMatching.type === "custom" && (
                                            <div className="mb-3">
                                                <Error>Passwords do not match</Error>
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className="d-flex flex-wrap justify-content-between">
                                    <div className={isMobile ? "w-100" : ""}>
                                        <FormControlLabel
                                            control={<Checkbox checked={stayLoggedIn} onChange={e => setStayLoggedIn(e.target.checked)} name="stay-logged-in" />}
                                            className="stay-logged-in-checkbox"
                                            label="Keep me logged in"
                                        />
                                    </div>
                                    {isLogin && state === States.LOGIN_USER_DETAIL && (
                                        <div className="text-white mt-1 pt-1 float-right">
                                            <Link href={Paths.resetPassword} underline="none" onClick={forgotPasswordClicked}>
                                                Forgot password?
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="row flex-wrap justify-content-between flex-row-reverse ps-2 pe-2">
                            {state === States.SIGNUP_USER_DETAIL && (
                                <button
                                    type="submit"
                                    disabled={!userCaptchaVerified || loading || (formState.errors.root && formState.errors.root.passwordNotMatching.type === "custom")}
                                    className="col btn btn-primary mt-3 ms-1 me-1"
                                    style={{ minWidth: "160px" }}
                                >
                                    {loading && <CircularProgress size="1rem" className="mr-1" />}
                                    Continue
                                </button>
                            )}
                            {state !== States.SIGNUP_USER_DETAIL && (
                                <button
                                    type="submit"
                                    disabled={!userCaptchaVerified || loading || (formState.errors.root && formState.errors.root.passwordNotMatching.type === "custom")}
                                    className="col btn btn-primary mt-3 ms-1 me-1"
                                    style={{ minWidth: "160px" }}
                                >
                                    {loading && <CircularProgress size="1rem" className="mr-1" />}
                                    {isLogin && state === States.LOGIN_USER_DETAIL ? "Log in" : "Continue"}
                                </button>
                            )}
                            {[States.LOGIN_USER_DETAIL, States.SIGNUP_USER_DETAIL].includes(state) && (
                                <button
                                    type="button"
                                    disabled={loading}
                                    className="col btn btn-outline-light mt-3 ms-1 me-1"
                                    style={{ minWidth: "160px" }}
                                    onClick={backBtnClicked}
                                >
                                    Back
                                </button>
                            )}
                        </div>
                    </form>
                    {!isSignupDisabled() && (
                        <p className="text-white m-auto pb-1 text-center mt-2">
                            {isLogin ? "Don't have an account?" : "Already have an account?"}
                            <button
                                className="pb-2 btn btn-link"
                                onClick={() => {
                                    setLogin(!isLogin);
                                    setState(isLogin ? States.SIGNUP_INITIAL_SCREEN : States.LOGIN_INITIAL_SCREEN);
                                }}
                            >
                                {isLogin ? "Sign up" : "Log in"}
                            </button>
                        </p>
                    )}
                </>
            )}
            {!accountTypeOfRegisteredUser && [States.SIGNUP_VERIFY_USER].includes(state) && (
                <>
                    <div>
                        <label className="p-1 mt-2" style={{ wordBreak: "break-word" }}>{`An email has been sent to ${maskEmail(getValues("email"))} with a security code.`}</label>
                    </div>
                    <div className="text-center mb-2">
                        <label className="p-1 mt-2 mb-3">Please enter the code below.</label>
                    </div>
                    <div className="d-flex justify-content-center otp mb-4">
                        <Desktop>
                            <OTPInput
                                value={code}
                                onChange={verifyCode}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                inputClassName="otp-input"
                                inputStyles={inputStyles}
                            />
                        </Desktop>
                        <Mobile>
                            <OTPInput
                                value={code}
                                onChange={verifyCode}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                inputClassName="otp-input"
                                inputStyles={{ ...inputStyles, width: "32px", fontSize: "21px" }}
                            />
                        </Mobile>
                    </div>
                    {codeError && (
                        <div className="text-center">
                            <Error>{codeError}</Error>
                        </div>
                    )}
                    <div className="d-flex justify-content-evenly pt-5 pb-4">
                        <ResendOTP
                            renderTime={() => Fragment}
                            renderButton={(props: any) => {
                                return (
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="text-white">{`If you havn't received an email${
                                                props.remainingTime ? ` in ${props.remainingTime} sec` : ""
                                            }, click the RESEND button.`}</div>
                                            <div>(Make sure to check your JUNK mailbox.)</div>
                                        </div>
                                        <div className="pt-1">
                                            <button className="btn btn-outline-primary p-3" {...props} onClick={resendCode}>
                                                Resend
                                            </button>
                                        </div>
                                    </div>
                                );
                            }}
                            maxTime="120"
                        />
                    </div>
                </>
            )}
            {!accountTypeOfRegisteredUser && (isSignupDisabled() || (!isLogin && !(state === States.SIGNUP_VERIFY_USER))) && (
                <p className="text-white m-auto mt-2 mb-3">
                    By continuing, you agree to Emovid's&nbsp;
                    <Link href={Paths.terms} className="btn-link" target="_blank" rel="noopener" underline="none">
                        Terms of Service
                    </Link>
                    &nbsp;and&nbsp;
                    <Link href={Paths.privacyPolicy} className="btn-link" target="_blank" rel="noopener" underline="none">
                        Privacy Policy
                    </Link>
                    .
                </p>
            )}
            {!!accountTypeOfRegisteredUser && (
                <div className="ps-0 pe-0">
                    <span className="text-underline">{getValues("email")}</span> is linked to a {capitalizeFirstChar(accountTypeOfRegisteredUser)} account. Please click&nbsp;
                    "Continue with {capitalizeFirstChar(accountTypeOfRegisteredUser)}" to sign in, or click on "Set Password" to create a login password for this account.
                    <div className="social-buttons pt-3">
                        <button
                            className="btn btn-lg google-button flex-grow-1 d-flex justify-content-md-around w-100 mb-2"
                            disabled={!userCaptchaVerified}
                            onClick={event => signIn(event, accountTypeOfRegisteredUser as AccountType)}
                        >
                            <img className="icon" src={`/icons/${accountTypeOfRegisteredUser}.svg`} alt={accountTypeOfRegisteredUser} />
                            <label className="text m-auto">Continue with {capitalizeFirstChar(accountTypeOfRegisteredUser)}</label>
                        </button>
                    </div>
                    <div className="row flex-wrap justify-content-between">
                        <button className="col cancel-button btn btn-outline-light mt-3 ms-1 me-1" style={{ minWidth: "160px" }} onClick={setPasswordClicked}>
                            Set Password
                        </button>
                        <button
                            className="col confirm-button btn btn-outline-light mt-3 ms-1 me-1"
                            style={{ minWidth: "160px" }}
                            onClick={() => setAccountTypeOfRegisteredUser("")}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={isVerifyingCode}>
                {isVerifyingCode && <CircularProgress color="inherit" />}
                {isVerifyingCode && <div className="ms-2">Verifying code...</div>}
            </Backdrop>
            <Popup isOpen={showEmbeddedBrowserError} onClose={undefined} className="embedded-browser-popup">
                <div className="container pt-3 pb-3 ps-0 pe-0">
                    <h5 className="m-auto pb-3 text-center" style={{ color: "#ffa726" }}>
                        <ReportProblemRoundedIcon color="warning"></ReportProblemRoundedIcon>
                        &nbsp;In-App Browser not supported!
                    </h5>
                    <IconButton className="close-icon text-white" aria-label="Close" onClick={embeddedBrowserDialogCloseClick}>
                        <CloseIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    <div className="mt-3 text-center">For a better Emovid experience, please do one of the following:</div>
                    <div className="mt-5 text-center">
                        Click the 3-dots button on your top right and select the option <i>Open in Browser</i>
                    </div>
                    <Divider className="mt-3">OR</Divider>
                    <div className="mt-3 text-center">Copy the link to your clipboard and paste it in the browser of your choice</div>
                    <div className="w-100 text-center">
                        <button className="btn btn-primary m-auto mt-3" onClick={copyLinkToClipboard}>
                            Copy to Clipboard
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

const Login: FC<LoginProps> = (props: LoginProps) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY || ""} container={{ parameters: { theme: "dark" } }}>
            <LoginComponent {...props} />
        </GoogleReCaptchaProvider>
    );
};

export default Login;

import { FC, MouseEventHandler, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { Tabs, Tab, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { AuthContext } from "../../Contexts/AuthContext";
import MobileNavLinks from "./MobileNavLinks";
import { Desktop, Mobile } from "../../Common/Responsive";
import { NavbarTab } from "../Dashboard/NavBarTabs";
import { Paths } from "../../Common/AppRoutes";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import HttpService from "../../Services/HttpService";
import { ITimeSavedData } from "../../Interfaces/ITimeSavedData";
import { checkPathMatches, isEmovidDomainUser } from "../../Common/Helper";
import NavLinks from "./NavLinks";
import Logo from "./Logo";

export const NavType = {
    default: 0,
    forgotPassword: 1
};

const NavBarContainer = styled.nav`
    color: white;
    justify-content: space-between;
    display: flex;
    width: 100%;
`;

const LeftSection = styled.div`
    display: flex;
`;
const CenterSection = styled.div`
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;
const RightSection = styled.div`
    display: block;
`;

export interface NavbarProps {
    theme?: string;
    breadcrumb?: number;
    maxBreadcrumb?: number;
    areFutureBreadcrumbsHot?: boolean;
    showBackButton?: boolean;
    navbarTabs?: NavbarTab[];
    selectedTabIndex?: number;
    showDashboardView?: boolean;
    hideCreateEmovidButton?: boolean;
    hideCreateEmovidButtonInMenu?: boolean;
    disableSignup?: boolean;
    popupModeTitle?: string;
    disableBreadcrumbs?: boolean;
    onBackClick?: MouseEventHandler<HTMLButtonElement>;
    onStepClicked?: (step: number) => void;
}

const NavBar: FC<NavbarProps> = (props: NavbarProps) => {
    const authContext = useContext(AuthContext);
    const [timeSavedData, setTimeSavedData] = useState<ITimeSavedData>(JSON.parse(sessionStorage.getItem("savedTime") || "{}"));
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (authContext.user?.userId)
            HttpService.get(`/v1/dashboard/time/saved`)
                .then(response => handleTimeSavedData(response))
                .catch(error => console.log(error));
    }, [authContext.user]);

    const handleTimeSavedData = (data: any) => {
        sessionStorage.setItem("savedTime", JSON.stringify(data));
        setTimeSavedData(data);
    };

    const handleTabChange = (e: any, index: number) => {
        navigate(((props.navbarTabs || [])[index] || {}).path || Paths.dashboard);
    };

    const handleStepClick = (step: number) => {
        if (props.breadcrumb !== step) props.onStepClicked?.(step);
    };

    const shouldShowTimeSaved = () => {
        let allowedPaths = [Paths.dashboard, Paths.myInbox, Paths.persona, Paths.createdVideos, Paths.viewEmovid, Paths.myAccount];
        return checkPathMatches(allowedPaths, location.pathname) && isEmovidDomainUser(authContext.user?.email);
    };

    const getLogoWidth = () => {
        if (isMobile && (location.pathname.endsWith("/record") || location.pathname.endsWith("/edit-video") || location.pathname.endsWith("/preview"))) return "35px";
        return "auto";
    };

    if (searchParams.get("popup_mode") === "true") {
        return (
            <>
                {props.theme === "dark" && <div style={{ height: isMobile ? "66px" : "70px" }}></div>}
                <NavBarContainer
                    className={"navbar fixed-top " + (props.theme === "dark" ? "shadow dark-theme" : "")}
                    style={props.theme !== "dark" ? { background: "linear-gradient(rgb(91, 23, 81), rgba(91, 23, 81, 0.6), transparent)" } : { background: "#4a4a4a" }}
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById("close-filter-btn")?.click();
                    }}
                >
                    <div id="nav-bar" className="container pt-2 pb-2">
                        <LeftSection>
                            <div style={{ width: getLogoWidth() }} className={props.theme === "dark" ? "overflow-hidden" : ""}>
                                <Logo className={props.theme === "dark" ? "" : "svg-shadow"} theme={props.theme} />
                            </div>
                        </LeftSection>
                        <CenterSection>
                            <div className="h5">{props.popupModeTitle || ""}</div>
                        </CenterSection>
                        <RightSection></RightSection>
                    </div>
                </NavBarContainer>
            </>
        );
    }

    return (
        <>
            {props.theme === "dark" && <div style={{ height: isMobile ? "66px" : "70px" }}></div>}
            <NavBarContainer
                className={"navbar fixed-top " + (props.theme === "dark" ? "shadow dark-theme" : "")}
                style={props.theme !== "dark" ? { background: "linear-gradient(rgb(91, 23, 81), rgba(91, 23, 81, 0.6), transparent)" } : { background: "#4a4a4a" }}
                onClick={e => {
                    e.stopPropagation();
                    document.getElementById("close-filter-btn")?.click();
                }}
            >
                <div id="nav-bar" className="container pt-2 pb-2">
                    <LeftSection>
                        {props.showBackButton && (
                            <button className="btn btn-link mt-2 text-white" onClick={props.onBackClick || undefined}>
                                <i className="fa-solid fa-arrow-left me-2"></i>BACK
                            </button>
                        )}
                        {!props.showBackButton && (
                            <Link
                                to={authContext?.user?.userId && isWhitelistedUser(authContext) ? Paths.dashboard : Paths.landing}
                                style={{ width: getLogoWidth() }}
                                className={props.theme === "dark" ? "overflow-hidden" : ""}
                            >
                                <Logo className={props.theme === "dark" ? "" : "svg-shadow"} theme={props.theme} />
                            </Link>
                        )}
                    </LeftSection>
                    <CenterSection>
                        {(props.breadcrumb || 0) > 1 && props.maxBreadcrumb && (
                            <Tooltip title="Previous step">
                                <IconButton disabled={(props.breadcrumb || 0) <= 1 || props.disableBreadcrumbs} onClick={() => handleStepClick((props.breadcrumb || 0) - 1)}>
                                    <KeyboardArrowLeftRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {props.maxBreadcrumb &&
                            [...Array(props.maxBreadcrumb)].map((index: any, i: any) => (
                                <button
                                    key={i}
                                    className={
                                        "btn btn-link number-breadcrumb" +
                                        (i + 1 <= (props.breadcrumb || 0) ? " elapsed clickable " : props.areFutureBreadcrumbsHot ? " clickable " : "") +
                                        (props.breadcrumb === i + 1 ? " current " : "")
                                    }
                                    disabled={props.disableBreadcrumbs}
                                    onClick={() => handleStepClick(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        {props.maxBreadcrumb && (props.breadcrumb || 0) < props.maxBreadcrumb && (
                            <Tooltip title="Next step">
                                <IconButton
                                    disabled={(props.areFutureBreadcrumbsHot ? (props.breadcrumb || 0) >= props.maxBreadcrumb : true) || props.disableBreadcrumbs}
                                    onClick={() => handleStepClick((props.breadcrumb || 0) + 1)}
                                >
                                    <KeyboardArrowRightRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {props.navbarTabs && (
                            <div className="navbar-tabs-container">
                                <Tabs value={props.selectedTabIndex} onChange={handleTabChange} aria-label="Dashboard tabs">
                                    {props.navbarTabs.map((navbarTab: NavbarTab, i: any) => (
                                        <Tab label={navbarTab.title} key={i} />
                                    ))}
                                </Tabs>
                            </div>
                        )}
                    </CenterSection>
                    <RightSection>
                        <Desktop>
                            <NavLinks
                                showDashboardView={props.showDashboardView}
                                hideCreateEmovidButton={props.hideCreateEmovidButton}
                                hideCreateEmovidButtonInMenu={props.hideCreateEmovidButtonInMenu}
                                disableSignup={props.disableSignup}
                                showTimeSaved={shouldShowTimeSaved()}
                                timeSavedData={timeSavedData}
                            />
                        </Desktop>
                        <Mobile>
                            <MobileNavLinks theme={props.theme} hideCreateEmovidButtonInMenu={props.hideCreateEmovidButtonInMenu} disableSignup={props.disableSignup} />
                        </Mobile>
                    </RightSection>
                </div>
            </NavBarContainer>
        </>
    );
};

export default NavBar;
